import {InputGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {useState} from "react";

const StickyHeader = ({breadCrumbs, currentPage, fetchData}) => {
    const [searchInput, setSearchInput] = useState("");

    const handleSearch = () => {
        if (searchInput.length > 2){
            fetchData("search", searchInput)
        }
    };

    return (
        <header className="sticky-top bg-dark headery">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">

                        <ul id="breadcrumb">

                            {breadCrumbs && breadCrumbs.map((item, index) => (
                                <li key={item.id} className={item.id === currentPage ? "disabled" : ""}>
                                    <a onClick={() => fetchData(item.id, 0)}>
                                        <span className={item.id === "be43dfef-7840-44cf-92f9-151b418c2e1c" ? "fa fa-home" : "fas fa-folder"}/> {item.id !== "be43dfef-7840-44cf-92f9-151b418c2e1c" && item.name}
                                    </a>
                                </li>
                            ))}

                        </ul>

                    </div>
                        <div className="col ">
                            <InputGroup className="search">
                                <Form.Control
                                    placeholder="search"
                                    aria-label="search box"
                                    aria-describedby="search-button"
                                    className="search-input"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <button className="input-group-text" id="search-button" onClick={handleSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                    </svg>
                                </button>
                            </InputGroup>
                        </div>

                </div>
            </div>

        </header>
    )
}

export default StickyHeader