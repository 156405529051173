import { useNavigate } from 'react-router-dom';
import getCookie from "./getCookie";
import { useEffect } from "react";
import FileExplorer from "./FileExplorer";
import "./static/css/style.css"
import TopNavBar from "./TopNavBar";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import StickyHeader from "./StickyHeader";
import "./FolderContainer"
import FolderContainer from "./FolderContainer";
import MainContainer from "./MainContainer";
// Home component responsible for rendering the main page after user authentication
export default function Home() {
    // Use the navigate function provided by React Router DOM
    const navigate = useNavigate();

    // Retrieve the authentication token from cookies
    let token = getCookie();

    // Effect to check if the user is authenticated
    useEffect(() => {
        // If the token is not available, redirect the user to the login page
        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    // Add event listener to the document for the contextmenu event to prevent the default behavior
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault(); // Prevent the default context menu behavior
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu); // Cleanup on component unmount
        };
    }, []);

    return (

            <MainContainer/>

    );
}
