import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Folder, FolderOpenOutlined, ImageOutlined, VideoFileOutlined } from "@mui/icons-material";
import { createOverlay } from "./createOverlay";
import { ClassifyFile } from "./ClassifyFiles";


const client = axios.create({
    baseURL: "http://24.144.77.216:8000"
});

// TreeView component for displaying folder structure
const TreeView = ({ setCurrentFolder, files, setShowOffcanvas }) => {
    const [treeCurrentFolder, setTreeCurrentFolder] = useState({});

    // Update current folder when treeCurrentFolder changes
    useEffect(() => {
        setCurrentFolder(treeCurrentFolder);
    }, [treeCurrentFolder, setCurrentFolder]);

    return (
        <div>
            <ul>
                {/* Pass setTreeCurrentFolder directly to TreeElement */}
                <TreeElement setShowOffcanvas={setShowOffcanvas} files={files} id={"root"} name={"root"} isDir={true} setCurrentFolder={setTreeCurrentFolder} />
            </ul>
        </div>
    );
};

// TreeElement component to represent each element in the tree
function TreeElement(props) {
    const [content, setContent] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [loading, setLoading] = useState(true);

    let id = props.id;
    if (props.id === "root") {
        id = "be43dfef784044cf92f9151b418c2e1c";
    }

    // Function to toggle expansion of folders
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        props.setCurrentFolder({ id: id, name: props.name });

        if (!fetched && props.isDir) {
            client.get(`/media/?id=${id}`).then(r => {
                setContent(r.data.content);
                setLoading(false);
            });
            setFetched(true);
        } else {
            createOverlay(props, props.files);
            props.setShowOffcanvas(false);
        }
    };

    // Function to handle folder click
    const handleFolderClick = () => {
        props.setCurrentFolder({ id: id, name: props.name });
        props.setShowOffcanvas(false);
    };

    return (
        <li id={id} key={id} className="clickable">
            {props.isDir ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isExpanded ? <Folder onClick={handleToggle} /> : <FolderOpenOutlined onClick={handleToggle} />}
                    <div onClick={handleFolderClick}> &nbsp;{props.name}</div>
                </div>
            ) : (
                ClassifyFile(props) === 'image' ? (
                    <div onClick={handleToggle} style={{ display: 'flex', alignItems: 'center' }}>
                        <ImageOutlined />
                        <div> &nbsp;{props.name}</div>
                    </div>
                ) : (
                    ClassifyFile(props) === 'video' && (
                        <div onClick={handleToggle} style={{ display: 'flex', alignItems: 'center' }}>
                            <VideoFileOutlined />
                            <div> &nbsp;{props.name}</div>
                        </div>
                    )
                )
            )}
            {/* Render child elements if expanded */}
            {isExpanded && (
                <ul>
                    {loading ? "Loading..." :
                        content.map((child) => (
                            <RenderTree key={child.id} setShowOffcanvas={props.setShowOffcanvas} files={props.files} id={child.id} name={child.name} isDir={child.isDir} url={child.url} setCurrentFolder={props.setCurrentFolder} />
                        ))}
                </ul>
            )}
        </li>
    );
}

// RenderTree component to render TreeElement with necessary props
function RenderTree(item) {
    return (
        <TreeElement key={item.id} setShowOffcanvas={item.setShowOffcanvas} files={item.files} id={item.id} name={item.name} isDir={item.isDir} setCurrentFolder={item.setCurrentFolder} url={item.url} />
    )
}

export default TreeView;
