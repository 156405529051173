import TelegramApp from "./TelegramApp";

const RightNavBar = () => {

    return (
        <div
            className="offcanvas offcanvas-end bg-dark text-white sidebar_collection"
            tabIndex={-1}
            id="offcanvasNavbar_R"
            aria-labelledby="offcanvasNavbarLabel"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title give-you" id="offcanvasNavbarLabel">
                    nudesksa.
                </h5>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body">
                <div className="telegram-Main p-10">
                    <TelegramApp/>
                </div>
            </div>
        </div>
    )

}

export default RightNavBar
