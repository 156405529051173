import React, { useEffect, useRef, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import TelegramPost from "./TelegramPost";

function TelegramApp() {
    // State to manage data and loading state
    const [data, setData] = useState({ content: [], offset: 0 });
    const [loading, setLoading] = useState(false);
    // Ref for the container
    const containerRef = useRef(null);

    useEffect(() => {
        // Function to fetch data on component mount
        const fetchDataOnMount = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://nudesksa.com/api/telegram/?add_offset=${data.offset}`);
                if (response.ok) {
                    const jsonData = await response.json();
                    // Update data state with new content and offset
                    setData(prevState => ({
                        content: [...prevState.content, ...jsonData.ids],
                        offset: jsonData.offset
                    }));
                } else {
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        };

        fetchDataOnMount();
    }, []); // Empty dependency array ensures the effect runs only once after initial render

    // Function to fetch more data
    const fetchMoreData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await fetch(`https://nudesksa.com/api/telegram/?add_offset=${data.offset}`);
            if (response.ok) {
                const jsonData = await response.json();
                // Update data state with new content and offset
                setData(prevState => ({
                    content: [...prevState.content, ...jsonData.ids],
                    offset: jsonData.offset
                }));
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching more data:', error);
        }
        setLoading(false);
    };

    // Function to handle fetching more data
    const handleFetchMoreClick = () => {
        fetchMoreData();
    };

    return (
        <div id="telegram-container" className="text-center" ref={containerRef}>
            {/* Display loading spinner when loading */}
            {loading && <Spinner className='' animation="border"/>}

            {/* Map over the content data and render TelegramPost components */}
            {data.content && data.content.map((item, index) => (
                <TelegramPost key={index} item={item}/>
            ))}

            {/* Button to fetch more data */}
            {!loading && (
                <button
                    className="btn btn-outline-secondary btn-lg mb-5"
                    onClick={handleFetchMoreClick} disabled={loading}
                >
                    <span className="fas fa-plus me-2"/>

                    <span>{loading ? 'Loading...' : 'عرض المزيد'}</span>
                </button>

    )
}
</div>
)
    ;
}

export default TelegramApp;
