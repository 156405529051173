const LeftNavBar = () => {
    return (
        <div
            className="offcanvas offcanvas-start bg-dark text-white sidebar-c"
            tabIndex={-1}
            id="offcanvasNavbar_L"
            aria-labelledby="offcanvasNavbarLabel"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title give-you" id="offcanvasNavbarLabel">
                    nudesksa.
                </h5>
                <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav flex-grow-1 pe-3">
                    <li className="nav-item mb-2">
                        <a
                            className="nav-link text-white p-2 d-flex align-items-center justify-content-start"
                            href="/"
                        >
                            <i className="fas fa-home me-2"/>
                            <span className="flex-fill text-center">الصفحة الرئيسية</span>
                        </a>
                    </li>
                    <li className="nav-item mb-2">
                        <a
                            className="nav-link text-white p-2 d-flex align-items-center justify-content-start"
                            href="#"
                        >
                            <i className="fas fa-history me-2"/>
                            <span className="flex-fill text-center">مشاهدتها مؤخرًا</span>
                        </a>
                    </li>
                    <li className="nav-item mb-2">
                        <a
                            className="nav-link text-white p-2 d-flex align-items-center justify-content-start"
                            href="#"
                        >
                            <i className="fas fa-fire me-2"/>
                            <span className="flex-fill text-center">الأكثر مشاهدة</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <a
                    href="/login"
                    className="btn btn-outline-danger btnLogout"
                    style={{width: "50%", margin: "100px 100px 100px 100px"}}
                >
                    تسجيل الخروج
                </a>
            </div>
        </div>
    )
}

export default LeftNavBar