import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const TopNavBar = () => {

    return (
        <nav className="navbar navbar-dark bg-dark">
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar_L"
                    aria-controls="offcanvasNavbar"
                    title="nudesksaMenu"
                >
                    <span className="navbar-toggler-icon"/>
                </button>
                <a className="navbar-brand mx-auto" href="/" title="Nudesksa">
                    <img
                        className="logo_initial"
                        src="https://silly-static.b-cdn.net/L-only.png"
                        width="200px"
                        alt=""
                        srcSet=""
                    />
                </a>
                <div className="d-flex justify-content-end">
                    {/* Telegram Icon */}
                <a
                    className="btn btn-outline-secondary me-1"
                    href="#"
                    style={{fontSize: "0.80rem"}}
                >
                      <span
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasNavbar_R"
                          aria-controls="offcanvasNavbar_R"
                      >
                        <i className="fab fa-telegram fa-lg"/> محتوى تلقرام الحصري
                      </span>
                   </a>
                </div>
            </div>

        </nav>
    )

}

export default TopNavBar