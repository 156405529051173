import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const Gallery = ({ items, specificItem , handleSpecificItemChange}) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [isOpen, setIsOpen] = useState(false); // State to track if gallery is open
    const [playingIndex, setPlayingIndex] = useState(null); // State to track the index of the currently playing video

    // Use useEffect to find the index of the specificItem
    useEffect(() => {
        if (specificItem) {
            const index = items.findIndex(item => item === specificItem);
            if (index !== -1) setCurrentIdx(index);
            setIsOpen(true); // Open the gallery when specificItem is provided
        }
    }, [specificItem, items]);

    const handleImageClick = (idx) => {
        setCurrentIdx(idx);
        setIsOpen(true); // Open the gallery when an image is clicked
        setPlayingIndex(null); // Reset the playingIndex when an image is clicked
    };

    const handleCloseGallery = () => {
        setIsOpen(false); // Close the gallery
        setPlayingIndex(null); // Reset the playingIndex when the gallery is closed
        handleSpecificItemChange(null)
    };

    const handleVideoPlay = (idx) => {
        setPlayingIndex(idx); // Set the playingIndex to the index of the video being played
    };

    const handleVideoPause = (idx) => {
        if (idx === playingIndex) {
            setPlayingIndex(null); // Reset the playingIndex when the video is paused
        }
    };

    const handleSlideChange = (selectedIndex, e) => {
        if (playingIndex !== null) {
            setPlayingIndex(null); // Reset the playingIndex when the slide changes
        }
    };

    return (
        <div className={`gallery ${isOpen ? 'open' : ''}`}>
            {isOpen && <div className="overlay" onClick={handleCloseGallery}></div>}
            <button className="close-button" onClick={handleCloseGallery}>Close</button>
            <Carousel activeIndex={currentIdx} onSelect={handleImageClick} onSlide={handleSlideChange} interval={null}>
                {items
                    .filter(item => !item.isDir)
                    .map((item, idx) => (
                    <Carousel.Item key={idx}>
                        {item.type === 'image' ? (
                            <img
                                src={item.url}
                                alt={item.name || 'Gallery Item'}
                                onClick={() => handleImageClick(idx)}
                                className="d-block w-100"
                            />
                        ) : (
                            <ReactPlayer
                                url={item.url}
                                height="80vh"
                                width="80vw"
                                controls
                                playing={playingIndex === idx}
                                onPlay={() => handleVideoPlay(idx)}
                                onPause={() => handleVideoPause(idx)}
                                autoPlay
                                className="player"
                            />
                        )}
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default Gallery;
