/**
 * Function to retrieve the authentication token from cookies.
 * @returns {string} The authentication token value if found, otherwise an empty string.
 */
export default function getCookie() {
    // Get all cookies from the document
    const cookies = document.cookie;

    // Split the string to get individual cookies
    const cookieArray = cookies.split(';');

    // Initialize authToken variable to store the authentication token
    let authToken = '';

    // Iterate over the array to find the authentication token cookie
    cookieArray.forEach(cookie => {
        // Split each cookie into its name and value
        const [name, value] = cookie.trim().split('=');

        // Check if the cookie name matches 'authToken'
        if (name === 'authToken') {
            // Set authToken to the value of the 'authToken' cookie
            authToken = value;
        }
    });

    // Return the authentication token
    return authToken;
}
