import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Col, Container, Row, Image } from "react-bootstrap";
import UserForm from "./UserForm";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import './static/css/login.css'
// Axios client setup
const client = axios.create({
    baseURL: "https://nudesksa.com/api",
    //baseURL: "http://127.0.0.1:8000",

});

// Login component for user authentication
export default function Login() {
    const navigate = useNavigate(); // React Router DOM navigation hook
    const [loginError, setLoginError] = useState(''); // State for login error messages
    const [loginSuccess, setLoginSuccess] = useState(''); // State for login success messages

    // Function to handle login form submission
    function submitLogin(username, password) {
        setLoginError(""); // Clear previous login error messages
        setLoginSuccess(""); // Clear previous login success messages

        // Send POST request to login endpoint
        client.post(
            "/login/",
            {
                username: username,
                password: password
            },
            {
                withCredentials: true, // Send cookies with the request
            }
        ).then(function (res) {
            // Handle successful login
            setLoginSuccess("Login Successful. Fetching Folders");

            // Set expiration date for the authentication token
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (60 * 60 * 1000)); // One hour in milliseconds
            const expiresUTC = expirationDate.toUTCString();

            // Set the authentication token in cookies
            // document.cookie = `authToken=${res.data.token}; expires=${expiresUTC}`;
            document.cookie = `authToken=${res.data.token};`;

            // Redirect to the home page
            navigate('/home');
        }).catch(function (error) {
            // Handle login errors
            console.error(error);
            setLoginError('Login failed. Please check your credentials.');
        });
    }

    return (
        <div className="container">
            {/* Display login error messages */}
            <Row>
                <Col>
                    {loginError && <Alert variant="danger">{loginError}</Alert>}
                </Col>
            </Row>
            {/* Display login success messages */}
            <Row>
                <Col>
                    {loginSuccess && <Alert variant="success">{loginSuccess}</Alert>}
                </Col>
            </Row>
            {/* Display logo and login form */}
            <a className="m" href="#"><i className=""><img src="https://silly-static.b-cdn.net/L-only.png" width="200px" alt="Logo"/></i></a>
            <div className="card">
                <div>
                    <UserForm onSubmit={submitLogin}/>
                </div>
            </div>
        </div>
    );
}
