import './App.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./login";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import getCookie from "./getCookie";
import {useEffect} from "react";


function App() {
    let token = getCookie()

  return (
      <div>
          <Router>
              <Routes>
                  {<Route path="/" element={<Home/>}/>}
                  {<Route path="/login" element={<Login/>}/>}
                  {<Route path="/home" element={<Home/>}/>}
              </Routes>
          </Router>
      </div>

  );
}

export default App;


