import React, { useEffect, useRef, useState } from 'react';
// import "./static/css/file_explorer.css"; // Assuming the CSS file path is correct
import Spinner from "react-bootstrap/Spinner";

function TelegramPost({ item }) {
    // State to manage loading state
    const [loading, setLoading] = useState(true);
    // Reference to the iframe element
    const iframeRef = useRef();

    // Update the iframe height when it loads
    useEffect(() => {
        // Listen for the postMessage event from the specific iframe
        const receiveMessage = (event) => {
            setLoading(false);
            // Check if the event data contains height and matches the iframe source
            if (event.data.height && event.source === iframeRef.current.contentWindow) {
                // Set the iframe height based on the received data
                iframeRef.current.style.height = `${event.data.height + 20}px`;
            }
        };

        // Add event listener for the postMessage event
        window.addEventListener('message', receiveMessage, false);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []); // Empty dependency array ensures the effect runs only once after initial render

    return (
        <div className="row mb-3">
            {/* Show spinner animation while loading */}
            {loading && <Spinner className="loading-animation" animation="border"/>}
            {/* Render the iframe */}
            <iframe
                className="telegram-iframe"
                ref={iframeRef}
                src={`https://nudesksa.com/api/telegram-iframe/?media_id=${item}`}
            >
            </iframe>
        </div>
    );
}

export default TelegramPost;
