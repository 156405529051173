import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./static/css/login.css"

// UserForm component for user login
const UserForm = ({ onSubmit }) => {
    // State variables for email and password
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        onSubmit(email, password); // Call onSubmit function with email and password
    };

    return (
        <Form  onSubmit={handleSubmit}> {/* Form component with handleSubmit function as onSubmit handler */}
            {/* Input field for email */}
            <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Control
                    type="text"
                    className="email-input"
                    placeholder="اسم المستخدم"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                />
            </Form.Group>
            {/* Input field for password */}
            <Form.Group className="mt-2" controlId="formBasicPassword">
                <Form.Control
                    type="password"
                    className="password-input"
                    placeholder="كلمة المرور"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Update password state on change
                />
            </Form.Group>

            {/* Submit button */}
            <Button className="login-button" variant="outline-light" type="submit">
                تسجيل الدخول
            </Button>
        </Form>
    );
}

export default UserForm;
