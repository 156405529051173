import axios from "axios";
import { useState, useEffect } from "react";
// import "./static/css/file_explorer.css";
import FileBrowser from "./FileBrowser";
import FileList from "./FileList";
import ExplorerActions from "./ExplorerActions";
import NavWithTree from "./Navigation";

// Axios client configuration
const client = axios.create({
    // baseURL: "http://24.144.77.216:8000",
    baseURL: "http://127.0.0.1:8000",
});

// FileExplorer component
export default function FileExplorer() {
    // State variables
    const [content, setContent] = useState([]); // Content of the current directory
    const [currentFolder, setCurrentFolder] = useState({id: 'be43dfef784044cf92f9151b418c2e1c', name: "root"}); // Current folder being viewed
    const [loading, setLoading] = useState(true); // Loading state
    const [breadcrumbTrail, setBreadcrumbTrail] = useState([{id: currentFolder.id, name: currentFolder.name, openable: true, isDir: true}]); // Breadcrumb trail
    let mostViewed = []
    let isRoot = false
    // Fetch files when current folder changes
    useEffect(() => {
        fetchFiles(currentFolder);
    }, [currentFolder.id]);

    // Function to fetch files for a directory
    const fetchFiles = async (directory) => {
        setLoading(true);
        try {
            const response = await client.get(`/media/?id=${directory.id}`);
            setContent(response.data);
            mostViewed = response.data.most_viewed
            updateBreadcrumbTrail(directory);
        } catch (error) {
            console.error('Error fetching directory content:', error);
            // Handle errors appropriately, e.g., display an error message
        } finally {
            setLoading(false);
        }
    };

    // Function to update the breadcrumb trail
    const updateBreadcrumbTrail = (directory) => {
        setBreadcrumbTrail(prevTrail => {
            const directoryIndex = prevTrail.findIndex(item => item.id === directory.id);
            if (directoryIndex !== -1) {
                // Directory already exists in the breadcrumb trail, remove all elements after it
                return prevTrail.slice(0, directoryIndex + 1);
            } else {
                // Directory doesn't exist, add it to the end of the breadcrumb trail
                return [...prevTrail, { id: directory.id, name: directory.name, openable: true, isDir: true }];
            }
        });
    };


    // useEffect to update grid file entry styles
    useEffect(() => {
        const elements = document.getElementsByClassName('gridFileEntry-0-2-30');
        // Convert HTMLCollection to array to use forEach method
        Array.from(elements).forEach(element => {
            element.style.width = '100px';
        });


    }, []);

    return (
        <div>
            {/* Navigation bar with tree */}
            <div id='main'>
                {/* File browser component */}
                <FileBrowser mostViewed={content.most_viewed} files={content.content} setCurrentFolder={setCurrentFolder} loading={loading}
                             breadcrumbs={breadcrumbTrail} currentFolder={currentFolder} breadcrumbTrail={breadcrumbTrail}>
                    {/* Explorer actions component */}
                    <ExplorerActions/>
                    {/* File list component */}
                    <FileList/>
                </FileBrowser>
            </div>
        </div>
    );
}
