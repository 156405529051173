import React, {Component, useEffect} from "react";
import TopNavBar from "./TopNavBar";
import LeftNavBar from "./LeftNavBar";
import RightNavBar from "./RightNavBar";
import StickyHeader from "./StickyHeader";
import {createOverlay} from "./createOverlay";


import ReactPlayer from 'react-player'
import Gallery from "./Gallery";


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            error: null,
            isLoading: false,
            displayedFolders: 25,
            context: 0,
            prevContext: 0,
            currentPage: "be43dfef-7840-44cf-92f9-151b418c2e1c",
            breadCrumbs: [],
            files: [],
            page: 1,
            specificItem: null
        };
    }

    fetchData = async (id, context) => {
        try {
            const url = `https://nudesksa.com/api/media/?id=${id}&context=${context}`;
            this.setState({
                isLoading: true,
                displayedFolders: 25,
                files: []
            });

            window.scrollTo({
                top: 0,
                behavior: "smooth" // Smooth scrolling animation
            });

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('End Of Page');
            }

            const data = await response.json();

            for (const item in data.content) {
                if (!data.content[item].isDir){
                    this.state.files.push(data.content[item])
                }
            }

            this.state.prevContext = this.state.context

            this.setState(prevState => ({
                data: data.content,
                error: null,
                context: data.next_context,
                currentPage: id,
                breadCrumbs: data.bread_crumbs,
            }));



        } catch (error) {
            console.error(error);
            this.setState({ error: error.message, data: [] });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    componentDidMount() {
        this.fetchData("be43dfef-7840-44cf-92f9-151b418c2e1c", this.state.context);
        this.applyOpacityAnimation(); // Call the animation function on initial mount
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.applyOpacityAnimation(); // Call the animation function whenever data changes
        }

        if (prevState.currentPage !== this.state.currentPage) {
            // Update the page state here
            this.setState({ page: 1 });
        }
    }

    applyOpacityAnimation = () => {
        const folders = document.querySelectorAll("#folderContainer .folder-upper");
        folders.forEach((folder, index) => {
            setTimeout(() => {
                folder.style.opacity = 1;
            }, index * 50); // Adjust the delay for desired animation speed
        });
    };

    handleShowMore = () => {
        this.setState(prevState => ({
            displayedFolders: prevState.displayedFolders + 25
        }), () => {

            // After updating the state, apply opacity animation to the newly added elements
            const newFolders = document.querySelectorAll(`#folderContainer .folder-upper:nth-child(n + ${this.state.displayedFolders - 25})`);
            newFolders.forEach((folder, index) => {
                setTimeout(() => {
                    folder.style.opacity = 1;
                }, index * 100); // Adjust the delay for desired animation speed
            });
        });
    };

    createGallery = (item) => {
        // Open the gallery with the specific item
        this.setState({
            specificItem: item
        })
    };


    nextPage = () => {
        this.fetchData(this.state.currentPage, this.state.context)
        this.state.page += 1
        console.log(this.state.prevContext)
    };


    prevPage = () => {
        this.fetchData(this.state.currentPage, (this.state.page -2) * 100)
        console.log(this.state.prevContext)
        this.state.page -= 1

    }

    handleSpecificItemChange = (specificItem) => {
        this.setState({ specificItem }); // Update specificItem in MainContainer state
    };

    renderFolders = () => {
        const { data, displayedFolders } = this.state;
        const displayedData = data.slice(0, displayedFolders);

        return displayedData.map((item) => {
            if (item.isDir) {
                return (
                    <div
                        key={item.id}
                        className="col mb-3 folder-upper"
                        draggable="false"
                        style={{ opacity: 0 }}
                        onClick={() => this.fetchData(item.id, 0)}
                    >
                        <div className="folder">
                            <img
                                className="folder-lower"
                                src="https://silly-static.b-cdn.net/folder_system_icon_inv_1.png"
                                alt=""
                                srcSet=""
                            />
                            <div className="folder-name" style={{ direction: 'rtl' }}>
                                {item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
                            </div>
                        </div>
                    </div>
                );
            } else {

                return (
                    <div
                        key={item.id}
                        className="col mb-3 folder-upper"
                        draggable="false"
                        style={{ opacity: 0 }}
                        onClick={() => this.createGallery(item)}
                    >
                        <div className="file">
                            <img
                                className="file-lower"
                                src={item.thumb}
                                alt={item.name}
                                srcSet=""
                            />
                            <div className="file-name" style={{ direction: 'rtl' }}>
                                {item.name.length > 15 ? item.name.substring(0, 15) + '...' : item.name}
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };


    render() {
        const {isLoading, error} = this.state;


        return (
            <>
                <TopNavBar/>
                <LeftNavBar/>
                <RightNavBar/>

                <StickyHeader breadCrumbs={this.state.breadCrumbs} currentPage={this.state.currentPage} fetchData={this.fetchData}/>

                <Gallery items={this.state.data} specificItem={this.state.specificItem} handleSpecificItemChange={this.handleSpecificItemChange} />

                <main className="main-container">
                    <div className="container-fluid d-flex justify-content-center align-items-center h-100 w">
                        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-auto"
                             id="folderContainer">
                            {this.renderFolders()}
                        </div>
                    </div>
                    {isLoading && (
                        <div className="container text-center mt-3">
                            <div id="loadingSpinner" className="spinner-border mt-3 mb-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className="container text-center mt-3">
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </div>
                    )}
                    <div className="container text-center mt-3">
                        <button
                            id="showMoreBtn"
                            className="btn btn-outline-secondary btn-lg mb-5"
                            onClick={this.handleShowMore}
                            disabled={this.state.data.slice(0, this.state.displayedFolders).length === this.state.data.length}
                        >
                            <span id="showMoreIcon" className="fas fa-plus me-2"/>
                            <span id="showMoreText">عرض المزيد</span>
                        </button>
                    </div>
                    <div className="pagination-buttons d-flex justify-content-center mt-1 mb-5">
                        <button
                            id="prevPageBtn"
                            className="btn btn-outline-secondary me-2"
                            title="Previous Page"
                            onClick={this.prevPage}
                            disabled={this.state.page === 1}

                        >
                            &lt; Prev
                        </button>
                        <span id="currentPage" className="align-self-center">
                      {this.state.page}
                    </span>
                        <button
                            id="nextPageBtn"
                            className="btn btn-outline-secondary ms-2"
                            title="Next Page"
                            onClick={this.nextPage}
                            disabled={!this.state.context}
                        >
                            Next &gt;
                        </button>
                    </div>
                </main>
            </>

        );
    }
}

export default MainContainer;
