import {BorderAll, Reorder, Search} from "@mui/icons-material";
import SearchBox from "./SearchBox";
// import './static/css/file_explorer.css';
import {Button} from "react-bootstrap";
import React from "react";
import { Collapse, Ripple, initMDB } from 'mdb-ui-kit';

initMDB({ Collapse, Ripple });

/**
 * Component for rendering actions in the file explorer.
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.files - The list of files.
 * @param {boolean} props.viewMode - The current view mode (grid or list).
 * @param {function} props.setViewMode - Function to set the view mode.
 * @param {function} props.setResults - Function to set the search results.
 * @param {Object} props.currentFolder - The current folder being viewed.
 * @param {Array} props.results - The search results.
 * @param {Array} props.breadcrumbs - The breadcrumbs for navigation.
 * @param {function} props.setCurrentFolder - Function to set the current folder.
 * @returns {JSX.Element} - The JSX element representing the ExplorerActions component.
 */
const ExplorerActions = ({ files, viewMode, setViewMode, setResults, currentFolder, results, breadcrumbs, setCurrentFolder }) => {
    /**
     * Toggles between grid and list view modes.
     */
    function handleViewMode() {
        setViewMode(!viewMode);
    }

    /**
     * Handles breadcrumb navigation.
     * @param {Object} event - The click event.
     */
    const handleBreadcrumb = (event) => {
        setCurrentFolder({ id: event.target.id, name: event.target.itemName });
    };

    return (
        <div id="action-row" className="container-fluid border border-opacity-75">
            <div className="row my-1 align-items-center">
                <div className="mt-1 d-flex flex-row " id="collapseExample">

                    {/* Search box and view mode toggle */}
                    <div className="flex-fill">
                        <SearchBox data={files}
                                   setResults={setResults}
                                   currentFolder={currentFolder}
                                   results={results}/>
                    </div>

                    <div className="viewToggle mx-2" style={{color: "white"}}>
                        {/* Toggle between grid and list view modes */}
                        {!viewMode ? <BorderAll onClick={handleViewMode}/> : <Reorder onClick={handleViewMode}/>}
                    </div>

                </div>

                {/* Breadcrumbs navigation */}
                <div className="col breadcrumb-container mb-1 mx-1">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 flex-nowrap">
                            {breadcrumbs.map((item, index) => (
                                index === breadcrumbs.length - 1 ? (
                                    <li key={item.id} id={item.id} className="breadcrumb-item text-truncate active"
                                        aria-current="page">{item.name}</li>
                                ) : (
                                    <li key={item.id} id={item.id} itemName={item.name}
                                        className="breadcrumb-item text-truncate"
                                        onClick={handleBreadcrumb}>{item.name}</li>
                                )
                            ))}
                        </ol>
                    </nav>
                </div>
            </div>
        </div>


    );
}

export default ExplorerActions;
